import { rtbInstance } from "@api/instance";
import { useMutation, useQuery } from "@tanstack/react-query";

export type Request = {
  title: string;
  description: string;
  files?: string[];
  deliverables?: string;
  skills: string[];
  budget: number;
  projectId: string;
  regional: string;
};

type Response = {
  jobId: string;
  projectId: string;
};

export const createJob = async (payload: Request): Promise<Response> => {
  const response = await rtbInstance.post(`/jobs`, payload);
  return response?.data;
};

const useCreateJob = () => {
  return useMutation({
    mutationFn: createJob,
  });
};

export default useCreateJob;
