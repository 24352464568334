import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";

import { ReactComponent as SuccessJobImage } from "@assets/images/job_success.svg";

interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  jobPath: string;
}

const SuccessCreateJobDialog = ({ isOpen, onClose, jobPath }: IProps) => {
  const {
    palette: { common },
  } = useTheme();
  const navigate = useNavigate();
  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      sx={{ ".MuiDialog-paper": { borderRadius: 6 } }}
    >
      <DialogContent>
        <Stack alignItems={"center"} pt={4} pb={8}>
          <SuccessJobImage />
          <Typography fontSize={30} fontWeight={600} color={"#1A202C"}>
            Woohoo! Your job is posted
          </Typography>
          <Typography fontSize={16} fontWeight={400} color={"#718096"}>
            Your job listing is now live and ready for proposals!
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: common.bellflowerBlue,
              color: common.gray5,
              borderRadius: 100,
              mt: 8,
            }}
            onClick={() => navigate(jobPath)}
          >
            View job post
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessCreateJobDialog;
