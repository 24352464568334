/* eslint-disable react/jsx-no-bind */
import { FC } from "react";
import { Controller } from "react-hook-form";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { TextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";

interface IDatePickerInput extends DatePickerProps<Date | Dayjs> {
  name: string;
  control: any;
  label?: string;
  // defaultValue?: string;
  textFieldProps?: TextFieldProps;
  [x: string]: unknown;
}

const DatePickerInput: FC<IDatePickerInput> = ({
  name,
  control,
  label,
  defaultValue,
  textFieldProps,
  ...others
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restField } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format={others.format || "DD/MM/YYYY"}
            label={label}
            // name={name}
            defaultValue={defaultValue || dayjs()}
            onChange={(e) => onChange(e)}
            slots={{ openPickerIcon: CalendarTodayIcon }}
            slotProps={{ textField: { ...textFieldProps } }}
            {...restField}
            {...others}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePickerInput;
