import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { createJob } from "@api/job/useCreateJob";
import { createFirstProjectWithRoundtable } from "@api/rtb/useCreateFirstNewProjectWithRoundtable";
import useFindUserSkills from "@api/skills/useFindUserSkills";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme,
  createFilterOptions,
  FilterOptionsState,
  InputAdornment,
  CssBaseline,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import * as yup from "yup";

import Button from "@components/Button";
import ConfirmDialog from "@components/ConfirmDialog/ConfirmAction";
import DatePickerInput from "@components/DatePicker";
import EllipsisContent from "@components/EllipsisContent";
import FormInput from "@components/FormInput";
import Loading from "@components/Loading";

import { Footer, Header } from "@pages/gatherlance-landing/components/HeaderAndFooter";

import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { ReactComponent as EditIcon } from "@assets/icons/pencil_edit.svg";
import bgBottom from "@assets/images/background_job.png";

import { ManagementEnum } from "@dto/roundtable";

import { useViewport } from "@contexts/ViewportContext";

import { ENV } from "@config/env";

import ProjectDisplay from "./components/ProjectDisplay";
import SuccessCreateJobDialog from "./components/SuccessCreateJobDialog";
import UploadAttachments from "./components/UploadFile";
import {
  fieldNameSchemas,
  FieldSchemas,
  INPUT_COMPONENT_TYPE,
  InputComponentProps,
  PostJobFormType,
} from "./types";

const helperTextSxProps: SxProps = {
  "& .MuiFormHelperText-root": {
    mx: 0,
    mt: 1,
  },
};

type OptionMode<T> = {
  value: T;
  text: string;
};

const regional: OptionMode<"US" | "WORLD">[] = [
  {
    value: "US",
    text: "US Only",
  },
  {
    value: "WORLD",
    text: "Worldwide",
  },
];

type JobFormType = {
  step?: number;
};

const filter = createFilterOptions();
let timeOutSearch: any = 0;

const formSchema = yup.object().shape({
  title: yup.string().required("Title is required."),
  description: yup.string().required("Description is required."),
  deliverables: yup.string().required("Deliverables is required"),
  budget: yup
    .number()
    .typeError("Budget must be a number")
    .required("Budget is required.")
    .min(5, "Minimum budget is 5 US Dollars"),
  skills: yup
    .array()
    .of(yup.object())
    .min(1, "Skills required at least 1")
    .required("Skills is required"),
  regional: yup.string(),
  project: yup.object().shape({
    id: yup.string().when("name", {
      is: (name: string) => name == null || name == "",
      then: (schema) =>
        schema.required("You need to enter project name or select project"),
      otherwise: (schema) => schema.nullable(),
    }),
  }),
});

const defaultValues: PostJobFormType = {
  title: "",
  description: "",
  files: [],
  deliverables: "",
  skills: [],
  budget: null,
  dueDate: dayjs(new Date()),
  project: {
    rtbId: "",
    id: "",
    name: "",
  },
  regional: "US",
};

export default function PostJobForm({ step: stepPost = 1 }: JobFormType) {
  const {
    palette: { common },
  } = useTheme();

  const [editMode, setEditMode] = useState("");
  const [skillInput, setSkillInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobPath, setJobPath] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const imageUploaderRef = useRef(null);

  const formMethods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const {
    control,
    setValue,
    watch,
    getValues,
    trigger,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = formMethods;
  const projectWatch = watch("project");
  const skillsWatch = watch("skills");
  const regionalWatch = watch("regional");

  const {
    skills: userSkills,
    handleChangeParams,
    isLoading: loadingSkills,
  } = useFindUserSkills({
    searchContent: skillInput,
  });
  const {
    mutate: createJobGtl,
    isPending,
    data,
  } = useMutation({
    mutationFn: createJob,
    onSuccess: (apiData) => {
      const path = `${ENV.RTB_APP_URL}/job/${apiData.jobId}`;
      setJobPath(path);
      setLoading(false);
    },
  });

  useEffect(() => {
    handleChangeParams({ searchContent: skillInput });
  }, [skillInput, handleChangeParams]);

  const fieldSchemas = useMemo<FieldSchemas>(
    () => ({
      title: {
        key: "title",
        component: "inputText",
        label: "Job title",
        step: 1,
        placeholder:
          "E.g. I need a new logo for my bakery shop to put on social media and packaging",
        required: true,
        gridCol: { xs: 12, md: 8 },
        componentProps: { required: true },
        sx: helperTextSxProps,
      },
      description: {
        key: "description",
        component: "area",
        label: "Job Description",
        placeholder: "Description",
        step: 1,
        required: true,
        gridCol: { xs: 12, md: 8 },
        componentProps: { required: true },
        sx: helperTextSxProps,
      },
      // files: {
      //   key: "files",
      //   component: "fileUpload",
      //   label: "",
      //   step: 1,
      //   gridCol: { xs: 12, md: 8 },
      //   componentProps: {
      //     ref: imageUploaderRef,
      //     acceptedFileTypes: "image",
      //   },
      // },
      deliverables: {
        key: "deliverables",
        component: "inputText",
        label: "Deliverables",
        placeholder: "What you expect to receive",
        step: 1,
        required: true,
        gridCol: { xs: 12, md: 8 },
        componentProps: { required: true },
        sx: helperTextSxProps,
      },
      skills: {
        key: "skills",
        component: "autoComplete",
        label: "Skills required",
        step: 1,
        options: userSkills?.map(({ id, name }) => ({ value: id, text: name })),
        icon: <SearchIcon />,
        required: true,
        gridCol: { xs: 12, md: 8 },
        overview:
          stepPost === 3 && editMode !== "skills"
            ? skillsWatch.map((option: any, index: number) => {
                return (
                  <Chip
                    variant="filled"
                    label={option.text || option.label}
                    sx={{
                      width: "fit-content",
                      backgroundColor: "#EDF2F7",
                    }}
                    key={option.value || index}
                  />
                );
              })
            : null,
        componentProps: {
          required: true,
          autoHighlight: true,
          filterSelectedOptions: true,
          loading: loadingSkills,

          onInputChange: (event: any, newInputValue: any) => {
            if (timeOutSearch) {
              clearTimeout(timeOutSearch);
            }

            timeOutSearch = setTimeout(async () => {
              setSkillInput(newInputValue);
            }, 1000);
          },
          getOptionLabel: (option: { title: string; text: string; value: string }) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.text) {
              return option.text;
            }

            return option.value;
          },
          ...(loadingSkills && {
            renderOption: () => (
              <Stack px={2}>
                <Loading />
              </Stack>
            ),
          }),
          filterOptions: (options: any[], params: FilterOptionsState<unknown>) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            const isExisting = options.some((option) => inputValue === option.text);
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                label: `Add ${inputValue}`,
                text: inputValue,
              });
            }

            return filtered;
          },
          multiple: true,
          value: getValues("skills"),
          onChange: (event: any, newValue: string[]) => {
            trigger("skills");
            setValue("skills", newValue);
          },
        },

        sx: {
          ...helperTextSxProps,
          "input": {
            pl: 5.5,
          },
        },
      },
      dueDate: {
        key: "dueDate",
        component: "datePicker",
        label: "Want it by",
        step: 1,
        gridCol: { xs: 12, md: 8 },
      },
      budget: {
        key: "budget",
        component: "inputText",
        label: "Budget",
        placeholder: "Enter budget (USD)",
        step: 1,
        required: true,
        gridCol: { xs: 12, md: 8 },
        componentProps: {
          required: true,
          type: "number",
          onPaste: (e: any) => {
            e.preventDefault();
            return false;
          },
        },
        sx: helperTextSxProps,
      },
      project: {
        key: "project",
        component: "project",
        label: "Project",
        step: 1,
        overview:
          stepPost === 3 && editMode !== "project" ? (
            <EllipsisContent
              fontSize={18}
              fontWeight={"bold"}
              numberOfLines={1}
              sx={{ mt: 0, wordBreak: "break-word" }}
              content={projectWatch.name}
            />
          ) : null,
        gridCol: { xs: 12, md: 8 },
        sx: helperTextSxProps,
      },
      regional: {
        key: "regional",
        component: "radio",
        label: "Regional setting",
        overview:
          stepPost === 3 && editMode !== "regional" ? (
            <Typography>
              {regional.find((item) => item.value === regionalWatch)?.text}
            </Typography>
          ) : null,
        step: 1,
        gridCol: { xs: 12, md: 8 },
        options: regional,
        sx: helperTextSxProps,
      },
    }),
    [
      editMode,
      getValues,
      loadingSkills,
      projectWatch.name,
      regionalWatch,
      setValue,
      skillsWatch,
      stepPost,
      trigger,
      userSkills,
    ]
  );

  const onClickPostJob = async () => {
    if (!isValid) {
      trigger();
    } else {
      clearErrors();
      setShowConfirmation(true);
    }
  };

  const onCreateNewProject = async (projectName: string, rtbId: string) => {
    const payload: any = {
      managementStyle: ManagementEnum.TRADITIONAL,
      roundTableId: rtbId,
      projectTitle: projectName,
      invitees: [],
      files: [],
      publishData: {
        information: ["MEMBERS"],
      },
    };
    const newProject = await createFirstProjectWithRoundtable({ payload });

    return newProject;
  };

  const onPostJob = handleSubmit(async (data: PostJobFormType) => {
    const {
      title,
      description,
      deliverables,
      skills,
      files,
      budget,
      project,
      regional,
      dueDate,
    } = data;
    setLoading(true);

    let newProject;
    if (project.name && !project.id) {
      newProject = await onCreateNewProject(project.name, project.rtbId);
    }

    const fileResults = await uploadImagesToServer(files);

    const fileList: string[] = [];
    if (fileResults?.length > 0) {
      fileList.push(...fileResults);
    }

    const payload = {
      title,
      projectId: project.id || newProject.projectId,
      skills,
      description,
      deliverables,
      isPublic: true,
      attachment: fileList,
      budget,
      regional,
      dueDate: dueDate.toDate(),
    };

    await createJobGtl(payload);
  });

  const handleDeleteTag = (index: number) => {
    const filteredTags = getValues("skills").filter(
      (_: string, i: number) => i !== index
    );

    setValue("skills", filteredTags);
  };
  const renderInputComponent = useCallback(
    ({
      name,
      placeholder,
      component,
      sx,
      icon,
      componentProps,
      options,
      overview,
      helperText,
    }: InputComponentProps) => {
      switch (component) {
        case INPUT_COMPONENT_TYPE.INPUT_TEXT:
          return (
            <Stack gap={0.5} position={"relative"}>
              {icon && (
                <IconButton
                  type="button"
                  disabled
                  sx={{ p: 1.25, position: "absolute", zIndex: 2 }}
                >
                  {icon}
                </IconButton>
              )}
              <FormInput
                {...componentProps}
                sx={{
                  ...sx,
                  ".MuiInputBase-root": {
                    borderRadius: 2,
                  },
                  "& input": { background: "white" },
                }}
                control={control}
                name={name}
                label={placeholder}
                size="small"
                error={!!errors?.[name]?.message}
                helperText={helperText || errors?.[name]?.message}
              />
            </Stack>
          );
        case INPUT_COMPONENT_TYPE.AREA:
          return (
            <Stack gap={0.5}>
              <FormInput
                {...componentProps}
                multiline
                maxRows={5}
                minRows={5}
                sx={{
                  ...sx,
                  background: "white",
                  ".MuiInputBase-root": {
                    borderRadius: 2,
                  },
                  "& input": { background: "white" },
                }}
                control={control}
                name={name}
                label={placeholder}
                size="small"
                error={!!errors?.[name]?.message}
                helperText={helperText || errors?.[name]?.message}
              />
            </Stack>
          );
        case INPUT_COMPONENT_TYPE.DATE_PICKER:
          return (
            <Stack gap={0.5}>
              <DatePickerInput
                textFieldProps={{ fullWidth: true, required: true, disabled: false }}
                control={control}
                name="duedate"
                sx={{
                  background: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2,
                  },
                }}
                size="large"
                disablePast
              />
            </Stack>
          );
        case INPUT_COMPONENT_TYPE.PROJECT:
          return (
            <Stack gap={0.5}>
              {!overview ? (
                <ProjectDisplay {...componentProps} />
              ) : (
                <Stack flexDirection={"row"} gap={0.5}>
                  {overview}
                </Stack>
              )}
            </Stack>
          );
        case INPUT_COMPONENT_TYPE.AUTOCOMPLETE:
          return (
            <Stack gap={0.5}>
              {!overview ? (
                <>
                  <Autocomplete
                    isOptionEqualToValue={(option: any, value) =>
                      option?.text === value?.text
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          size="small"
                          sx={{
                            background: "white",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                            },
                          }}
                          {...(name === "skills" && {
                            InputProps: {
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">{icon}</InputAdornment>
                              ),
                            },
                          })}
                        />
                      );
                    }}
                    renderTags={() => null}
                    options={options}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid
                              item
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              <Typography variant="body2" color="text.secondary">
                                {option.label || option.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                    {...componentProps}
                  />
                  {name === "skills" && skillsWatch.length > 0 && (
                    <Stack flexDirection={"row"} gap={0.5} flexWrap={"wrap"} mt={1}>
                      {skillsWatch.map((option: any, index: number) => {
                        return (
                          <Chip
                            variant="filled"
                            label={option.text || option.label}
                            sx={{
                              width: "fit-content",
                              backgroundColor: "#EDF2F7",
                            }}
                            key={option.value || index}
                            onDelete={() => handleDeleteTag(index)}
                          />
                        );
                      })}
                    </Stack>
                  )}
                </>
              ) : (
                <Stack flexDirection={"row"} gap={0.5}>
                  {overview}
                </Stack>
              )}
              {errors?.[name]?.message && (
                <Typography
                  component={"p"}
                  fontSize={12}
                  sx={{
                    color: "#d32f2f",
                  }}
                >{`This field is required`}</Typography>
              )}
            </Stack>
          );
        case INPUT_COMPONENT_TYPE.RADIO:
          return !overview ? (
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  defaultValue={options[0].value}
                  {...componentProps}
                >
                  {options?.map(({ value, text }) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      {...componentProps}
                      control={<Radio />}
                      label={text}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          ) : (
            <>{overview}</>
          );

        case INPUT_COMPONENT_TYPE.FILE_UPLOAD:
          return (
            <Stack gap={1.5}>
              <UploadAttachments
                onChangeFiles={(data) => setValue(name, data)}
                {...componentProps}
              />
            </Stack>
          );
        case INPUT_COMPONENT_TYPE.CHECKBOX:
          return (
            <Stack gap={0.5}>
              <FormControlLabel
                label={placeholder}
                control={<Checkbox {...componentProps} />}
                labelPlacement="end"
                sx={{
                  "& span.MuiCheckbox-root": { p: 0, mx: "9px" },
                }}
              />
            </Stack>
          );

        default:
          return <>DEFAULT</>;
      }
    },
    [control, errors, skillsWatch, handleDeleteTag, setValue]
  );
  const toggleEditMode = (editStep: string) => () => {
    setEditMode((prev) => (prev === editStep ? "" : editStep));
  };
  return (
    <Stack>
      <CssBaseline />
      <Stack
        sx={{ position: "sticky", top: 0, width: "100%", bgcolor: "white", zIndex: 10 }}
      >
        <Header />
      </Stack>

      <Stack
        px={{ sm: 3, md: 10 }}
        sx={{
          backgroundImage: `url(${bgBottom})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container item pt={4} minHeight={600} justifyContent={"center"}>
          <Grid item xs={12} md={8} justifyContent={"center"}>
            <Typography fontWeight={700} fontSize={{ xs: 24, md: 30 }} pt={10} pb={6}>
              Create a job
            </Typography>
          </Grid>
          {stepPost === 2 && (
            <Grid
              xs={12}
              md={8}
              item
              p={"16px 32px"}
              sx={{
                border: common.border,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              }}
            >
              <Typography fontWeight={"bold"} fontSize={18} flex={1}>
                Job details
              </Typography>
            </Grid>
          )}
          <FormProvider {...formMethods}>
            {Object.values(fieldSchemas)?.map(
              (
                {
                  gridCol,
                  key,
                  label,
                  required,
                  step,
                  icon,
                  placeholder,
                  component,
                  overview,
                  componentProps,
                  options,
                  helperText,
                  sx,
                },
                i
              ) => {
                const overViewStep = stepPost === 2;
                const sxStyle = {
                  alignItems: key === "title" && "center",
                  borderLeft: overViewStep ? common.border : "unset",
                  borderRight: overViewStep ? common.border : "unset",
                  borderTop:
                    overViewStep && ["title", "project"].includes(key)
                      ? common.border
                      : "unset",
                  borderBottom:
                    overViewStep &&
                    ["files", "deliverables", "skills", "budget", "regional"].includes(
                      key
                    )
                      ? common.border
                      : "unset",
                  borderEndStartRadius: ["regional", "budget"].includes(key) ? 12 : 0,
                  borderEndEndRadius: ["regional", "budget"].includes(key) ? 12 : 0,
                  borderStartStartRadius: ["project"].includes(key) ? 12 : 0,
                  borderStartEndRadius: ["project"].includes(key) ? 12 : 0,
                  padding: overViewStep ? "24px 32px" : "unset",
                  mb: overViewStep ? 0 : 4,
                  mt: overViewStep && ["project"].includes(key) ? 4 : 0,
                  display: step !== stepPost && stepPost < 2 ? "none" : "flex",
                };

                componentProps = {
                  ...componentProps,
                  disabled: overViewStep && key !== "files" && editMode !== key,
                  ...(overViewStep &&
                    editMode !== key && {
                      variant: "standard",
                      InputProps: {
                        disableUnderline: true,
                      },
                    }),
                };
                const hideLabel =
                  overViewStep &&
                  ["title", "description", "regional", "availability"].includes(key);
                return (
                  <Grid item key={key} {...gridCol} sx={sxStyle}>
                    <Stack flex={1} gap={1.5}>
                      {overViewStep && key === "project" && (
                        <Box
                          flex={1}
                          pl={4}
                          pb={2}
                          sx={{
                            width: "calc(100% + 64px)",
                            marginLeft: -4,
                            borderBottom: common.border,
                          }}
                        >
                          <Typography fontWeight={"bold"} fontSize={18}>
                            Other details
                          </Typography>
                        </Box>
                      )}
                      <Stack
                        flex={1}
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        {/* {!hideLabel && ( */}
                        <Typography fontWeight={"bold"} fontSize={18} flex={1}>
                          {label}
                          {required && (
                            <Typography component={"span"} color="common.partyTime">
                              *
                            </Typography>
                          )}
                        </Typography>
                        {/* )} */}
                        {overViewStep && key !== "files" && (
                          <IconButton type="button" onClick={toggleEditMode(key)}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </Stack>

                      {renderInputComponent({
                        name: key as fieldNameSchemas,
                        placeholder,
                        component,
                        overview,
                        ...((!overViewStep || editMode === key) && { icon }),
                        componentProps,
                        options,
                        helperText,
                        sx,
                      })}
                    </Stack>
                  </Grid>
                );
              }
            )}

            <Grid xs={12} md={8}>
              <Stack
                gap={1.5}
                flex={1}
                height={92}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <ActionButton
                  disabled={isPending || loading}
                  onClick={onClickPostJob}
                  primary
                >
                  Post this job
                </ActionButton>
              </Stack>
            </Grid>
          </FormProvider>
          {showConfirmation && (
            <ConfirmDialog
              title="Post job"
              description="Your job will be posted on the Gatherlance. Are you sure to post it?"
              submitBtnLabel={"Post it"}
              cancelBtnLabel="Let me check again!"
              isOpen={showConfirmation}
              onSubmit={onPostJob}
              onClose={() => setShowConfirmation(false)}
            />
          )}
          {jobPath && (
            <SuccessCreateJobDialog isOpen={Boolean(jobPath)} jobPath={jobPath} />
          )}
          {/* <CreateDirectProjectDialog
            isOpen={openCreateProject}
            onClose={() => setOpenCreateProject(false)}
          /> */}
        </Grid>
      </Stack>
      <Box mt={"95px"}>
        <Footer />
      </Box>
    </Stack>
  );
}
const ActionButton = styled(Button)<{ primary?: boolean }>(({ primary }) => ({
  color: primary ? "#fff" : "#000",
  backgroundColor: primary ? "#000" : "#fff",
  width: 140,
  padding: "12px auto",
  fontSize: 16,
  fontWeight: 600,
  borderRadius: 100,
  "&:hover": {
    backgroundColor: primary ? "#0000005e" : "#0000005e",
    color: primary ? "#fff" : "#000",
  },
}));
